<template>
  <div>
      <EditDirectionForm></EditDirectionForm>
  </div>
</template>

<script>
import EditDirectionForm from '../../../components/Dashboard/Directions/EditDirectionForm'
export default {
  components: { EditDirectionForm },

}
</script>

<style>

</style>